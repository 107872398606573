<template>
    <div class="modal-header modal-header_no-border">
        <div class="sharing-modal">
            <div class="sharing-modal__header">
                <div class="header-text">
                    <h3 class="header-text__title">
                        Открыть доступ всем
                    </h3>
                    <p class="header-text__subtitle">
                        Материал будет доступен всем по прямой ссылке
                    </p>
                </div>
                <toggle-button
                    class="m-0"
                    :color="colors"
                    :height="20"
                    :width="40"
                    v-model="isSharing"
                    :sync="true"
                    @input="changed"
                />
            </div>
            <div
                v-if="isSharing"
                type="button"
                class="block-input"
                @click="copyBuffer"
            >
                <input class="block-input__input" type="text" v-model="link" />
                <button class="block-input__button">
                    <icon
                        class="block-input__button-icon"
                        width="12"
                        height="14"
                    >
                        <path
                            d="M10.2282 0H4.34642C3.49206 0 2.79694 0.695113 2.79694 1.54948V1.73965H1.77213C0.917769 1.73965 0.222656 2.43476 0.222656 3.28912V12.4506C0.222656 13.305 0.917769 14.0001 1.77213 14.0001H7.65387C8.50823 14.0001 9.20328 13.305 9.20328 12.4506V12.2604H10.2281C11.0825 12.2604 11.7776 11.5653 11.7776 10.7109V1.54948C11.7776 0.695113 11.0825 0 10.2282 0ZM8.20386 12.4506C8.20386 12.7538 7.95712 13.0006 7.65394 13.0006H1.77213C1.46889 13.0006 1.22215 12.7538 1.22215 12.4506V3.28906C1.22215 2.98581 1.46889 2.73907 1.77213 2.73907H7.65387C7.95712 2.73907 8.20379 2.98581 8.20379 3.28906V12.4506H8.20386ZM10.7781 10.7109C10.7781 11.0142 10.5314 11.2609 10.2282 11.2609H9.20335V3.28906C9.20335 2.43469 8.50823 1.73958 7.65394 1.73958H3.79644V1.54941C3.79644 1.24617 4.04318 0.999424 4.34642 0.999424H10.2282C10.5314 0.999424 10.7781 1.24617 10.7781 1.54941V10.7109Z"
                            fill="currentColor"
                        />
                    </icon>
                    <transition name="fade">
                        <p class="block-input__button-info" v-if="isCopied">
                            Ссылка скопирована
                        </p>
                    </transition>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/components/icon/Icon'
    import session from '@/api/session'

    export default {
        name: 'SharingModal',
        components: {
            Icon
        },
        data() {
            return {
                isSharing: false,
                link: `${location.origin}/material_review/${this.material.id}/?sharing=${this.material.sharing}`,
                colors: { checked: '#FBC04F', unchecked: '#EEEEEE' },
                isCopied: false,
                timerId: null
            }
        },
        props: {
            material: {
                type: Object,
                required: true
            }
        },
        methods: {
            copyBuffer() {
                if (this.timerId) {
                    clearTimeout(this.timerId)
                }
                this.isCopied = true
                navigator.clipboard.writeText(this.link)
                this.timerId = setTimeout(() => {
                    this.isCopied = false
                    console.log(this.isCopied)
                }, 2000)
            },
            async changed() {
                await session.post(
                    `/api/v1/material/${this.material.id}/set_sharing/`
                )
                this.material.is_sharing = this.isSharing
            }
        },
        mounted() {
            document.body.style.overflow = 'hidden'
            this.isSharing = this.material.is_sharing
        },
        beforeDestroy() {
            document.body.style.overflow = ''
        }
    }
</script>

<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .sharing-modal {
        flex: 1;
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__block-input {
            flex: 1;
            display: flex;
            align-items: center;
        }
    }
    .block-input {
        margin-top: 22px;
        flex: 1;
        display: flex;
        align-items: center;
        border: 1px solid #d5dbe4;
        border-radius: 2px;
        transition: border 200ms ease;
        &:focus-within {
            border-color: #97a5ba;
            outline: 0;
            box-shadow: none;
            & .block-input__input {
                border-color: #97a5ba;
            }
        }
        &__input {
            flex: 1;
            border: none;
            border-right: 1px solid #d5dbe4;
            height: 38px;
            padding: 0 12px;
            transition: border 200ms ease;
        }
        &__button {
            position: relative;
            border: none;
            font: inherit;
            color: inherit;
            background-color: transparent;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0 12px;
            &-icon {
                color: #989cae;
                transition: color 200ms ease;
                &--isCopied {
                    color: #50b440;
                }
            }
            &-info {
                position: absolute;
                top: -28px;
                left: -60px;
                margin: 0;
                font-weight: normal;
                font-size: 10px;
                line-height: 14px;
                color: #9d9d9e;
                white-space: nowrap;
            }
            &:hover {
                & .block-input__button-icon {
                    color: black;
                    &--isCopied {
                        color: #50b440;
                    }
                }
            }
        }
    }
    .header-text {
        display: flex;
        flex-direction: column;
        &__title {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
        &__subtitle {
            margin: 0;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #9d9d9e;
        }
    }
</style>
